import { TextInput } from "@mantine/core";
import RoomGroup from "./RoomGroup.jsx";
import { useState } from "react";
import { IconSearch } from "@tabler/icons-react";

export default function RoomGroupList({ groups }) {
  const [roomsSearch, setRoomsSearch] = useState("");

  return (
    <>
      <TextInput
        placeholder="Hledat pokoj..."
        mb="md"
        onChange={(event) => setRoomsSearch(event.currentTarget.value)}
        value={roomsSearch}
        leftSection={<IconSearch stroke={1.5} size={16} />}
        onFocus={(e) => e.target.select()}
      />
      {groups.map((group) => {
        return <RoomGroup group={group} key={group.id} search={roomsSearch} />;
      })}
    </>
  );
}
